import React, {useEffect, useState} from "react";
import {clone} from "lodash";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";

export const CurrencyRate = (props) => {
    const setCurrencyRate = (index, index2 , rate) => {
        props.setLoading(true);
        let s = clone(props.items);
        s[index].calculatedCostsEntries[index2].currencyRate = rate;
        props.setItems(s);
        props.debouncedUpdateTrigger('rate', index, index2);
       // UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
        props.setLoading(false);
    }
    const CHANGE_KEY = 'order-calculation';
    return (
        <>
            { props.entry.price.currency != "EUR" &&
                <div className="form-group col-xs-12 col-md-6" >

                    <label
                        htmlFor="exampleInputPassword1" style={{fontWeight: "bold",color: "#7a7474", fontSize: "12px"}} >Kalkulationskurs *</label>
                    <input
                        onChange={(event) => {
                            setCurrencyRate(props.index, props.index2, event.target.value);
                        }}
                        type="number"
                        className="form-control"
                        value={props.entry.currencyRate}
                        id="exampleInputPassword1"
                    />

                </div>
            }
            {props.entry.price.currency != "EUR" &&
                <div className="form-group col-xs-12 col-md-6">
                    <label htmlFor="exampleInputPassword1"
                           style={{fontWeight: "bold", color: "#7a7474", fontSize: "12px"}}>Sicherheits-Marge
                        in %</label>
                    <div style={{background: '#bcb8b8', padding: '5px'}}>{props.entry.securityMargin}</div>
                </div>
            }
        </>
    );
}

export default CurrencyRate;