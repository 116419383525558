import moment from 'moment';
import { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { Panel } from 'rsuite';
import { debounce } from 'lodash';
import update from 'immutability-helper';
import { OfferSearchTabs, TABS, TAB_KEY } from './offer-search-tabs';
import { OfferSearchResults } from './offer-search-results';
import './offer-search-form.less';
import { OrderContext } from '../helper/order-context';
import { isAllDayEvent } from '../helper/events-utilities';
import { SORT_OPTIONS } from '../constants/index';

const tabsWithDefaultOccupancy = [TAB_KEY.ROOM, TAB_KEY.FERRY, TAB_KEY.SHIP];
const tabsWithRadius = [
  TAB_KEY.ROOM,
  TAB_KEY.SERVICE,
  TAB_KEY.SHIP,
  TAB_KEY.FERRY,
];
const tabsWithSRGKategorie = [TAB_KEY.ROOM];

const getDefaultFilters = (tab, { cities }, minHotelCategory, minFare) => {
  return {
    ...(tabsWithSRGKategorie.includes(tab) && {
      srgCategory: { $eq: minHotelCategory },
      fare: { $eq: minFare },
    }),
    ...(tabsWithRadius.includes(tab) && { radius: '20' }),
    ...(TAB_KEY.SERVICE === tab && { alongTheRoute: true }),
    sort: SORT_OPTIONS[0].value,
  };
};

export const OfferSearchForm = ({
  firstDate,
  participants,
  onResultSelection,
  selectedSearchItem,
  clearSelectedItem,
  onResultSearch,
  onResultShowPurchase,
  minHotelCategory,
  minFare,
  showMap,
  handleShowMap,
}) => {
  const { showErrorMessage, client, orderItems, order } = useContext(
    OrderContext
  );
  const [activeSearchTab, setActiveSearchTab] = useState(TAB_KEY.ROOM);
  const [isLoading, setIsLoading] = useState(true);

  const [searchFreeText, setSearchFreeText] = useState('');

  const [searchResults, setSearchResults] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDefaultOccupancy, setIsDefaultOccupancy] = useState(true);
  const [showPurchase, setShowPurchase] = useState(true);
  const [isTwinPosition, setIsTwinPosition] = useState(true);
  const [isNoPrice, setNoPrice] = useState(false);

  const cities = useMemo(
    () =>
      orderItems
        .filter((item) =>
          isAllDayEvent(item?.fromDate, item?.toDate, item.provider)
        )
        .filter((item) => {
          const start = moment(item.fromDate).startOf('day');
          const end = moment(item.toDate ?? 8.64e11).startOf('day');
          const isSameOrAfter =
            firstDate && firstDate.isSameOrAfter(start, 'day');
          const isBefore = firstDate && firstDate.isBefore(end, 'day');
          return isSameOrAfter && isBefore;
        })
        .map((item) => item?.product?.city)
        .filter(Boolean),
    [orderItems, firstDate]
  );

  const [filters, setFilters] = useState(
    getDefaultFilters(activeSearchTab, { cities }, minHotelCategory, minFare)
  );

  const setFilter = useCallback(
    (filterKey, filterValue) => {
      if (filterValue != null) {
        setFilters(update(filters, { [filterKey]: { $set: filterValue } }));
      } else {
        setFilters(update(filters, { $unset: [filterKey] }));
      }
    },
    [filters]
  );

  const switchTab = useCallback(
    (newActive) => {
      setFilters(
        getDefaultFilters(newActive, { cities }, minHotelCategory, minFare)
      );
      setActiveSearchTab(newActive);
      clearSelectedItem();
      setSearchFreeText('');
    },
    [cities]
  );

  const searchItems = async (
    tab,
    searchText,
    filtersObj,
    isDefaultOccupancy,
    isTwinPosition,
    isNoPrice
  ) => {
    const url = `offer-search/${tab}/${order.id}`;

    const params = new URLSearchParams();

    if (participants) {
      params.set('amount', participants);
    }
    params.set('date', (firstDate || moment()).format('YYYY-MM-DD'));
    const conditions = { ...filtersObj };

    if (searchText !== '') {
      conditions.search = searchText;
    }

    if (tabsWithDefaultOccupancy.includes(tab)) {
      conditions.defaultOccupancyTypes = isDefaultOccupancy;
    }

    if (tabsWithDefaultOccupancy.includes(tab)) {
      conditions.twinPosition = isTwinPosition;
    }

    if (isNoPrice) {
      conditions.noPrice = true;
    }

    params.set('conditions', JSON.stringify(conditions));

    setSearchResults([]);
    setErrors([]);
    setIsLoading(true);
    clearSelectedItem();

    client.get(`${url}?${params.toString()}`).then(
      (data) => {
        setSearchResults(data?.items ?? []);
        setErrors(data?.errors ?? {});
        setIsLoading(false);
        setShowPurchase(data.showPurchasePrice);
      },
      (err) =>
        showErrorMessage(
          err?.data?.localizedMessage ??
            err?.data?.message ??
            'Fehler bei der Suche'
        )
    );
  };

  const debouncedSearchItems = useCallback(debounce(searchItems, 500), [
    firstDate,
    participants,
  ]);

  const onSelectResult = (item) => {
    onResultSelection(item);
  };

  useEffect(() => {
    onResultSearch(searchResults);
  }, [searchResults]);

  useEffect(() => {
    debouncedSearchItems(
      activeSearchTab,
      searchFreeText,
      filters,
      isDefaultOccupancy,
      isTwinPosition,
      isNoPrice
    );
  }, [
    debouncedSearchItems,
    activeSearchTab,
    searchFreeText,
    filters,
    firstDate,
    isDefaultOccupancy,
    isTwinPosition,
    isNoPrice,
  ]);

  useEffect(() => {
    onResultShowPurchase(showPurchase);
  }, [showPurchase]);

  const OfferSearchOptions = useMemo(() => TABS[activeSearchTab].component, [
    activeSearchTab,
  ]);
  const sortOptions = SORT_OPTIONS.filter(
    ({ value }) => !(TAB_KEY.ROOM !== activeSearchTab && value === 'category')
  );
  return (
    <Panel
      bordered
      className="offer-search-form__container"
      style={{ display: selectedSearchItem ? 'none' : 'block' }}
    >
      <div className="offer-sidebar">
        <OfferSearchTabs
          activeSearchTab={activeSearchTab}
          switchTab={switchTab}
          showMap={showMap}
          handleShowMap={handleShowMap}
        />
        <div className="row offer-search-form-options__container">
          <OfferSearchOptions
            filters={filters}
            sortOptions={sortOptions}
            searchFreeText={searchFreeText}
            setSearchFreeText={setSearchFreeText}
            activeSearchTab={activeSearchTab}
            setFilter={setFilter}
            isDefaultOccupancy={isDefaultOccupancy}
            setIsDefaultOccupancy={setIsDefaultOccupancy}
            isTwinPosition={isTwinPosition}
            setIsTwinPosition={setIsTwinPosition}
            isNoPrice={isNoPrice}
            setNoPrice={setNoPrice}
            minHotelCategory={minHotelCategory}
            minFare={minFare}
          />
        </div>
        <OfferSearchResults
          isLoading={isLoading}
          selectedSearchItem={selectedSearchItem}
          searchResults={searchResults}
          showPurchase={showPurchase}
          errors={errors}
          onSelectResult={onSelectResult}
        />
      </div>
    </Panel>
  );
};
