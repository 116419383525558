import React, {useEffect, useState} from "react";
import {clone} from "lodash";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";

export const CalculationType = (props) => {
    const setCalculationType = (index, type) => {
        props.setLoading(true);
        let s = clone(props.items);
        s[index].calculationType = type;
        props.setItems(s);
        props.debouncedUpdateTrigger('purchase', index);
       // UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
        props.setLoading(false);
    }
    const CHANGE_KEY = 'order-calculation';
    return (
        <>
            <select
                className="form-control"
                value={props.item.calculationType}
                onChange={(event) => {
                    setCalculationType(props.index, event.target.value);
                }}
            >
                <option value="total">Gesamtpreis</option>
                {props.item.provider == 'tourism-accommodation/room' ?
                    <option value="proPersonNight">Pro Person und Nacht</option>
                    :
                    <option value="proPerson">Pro Person</option>
                }
            </select>
        </>
    );
}

export default CalculationType;