import React, {useEffect, useState} from "react";
import {clone} from "lodash";

export const EntryPurchasePrice = (props) => {
    const setEntryPrice = (index, index2 , purchase) => {
         props.setLoading(true);
         let s = clone(props.items);
         s[index].calculatedCostsEntries[index2].price['amount'] = purchase;
         props.setItems(s);
         props.debouncedUpdateTrigger('purchase', index, index2);
         //UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
        props.setLoading(false);
    }
    const CHANGE_KEY = 'order-calculation';
    return (
        <>
            <div className='form-group col-xs-12 col-md-6'>
                <label htmlFor="exampleInputPassword1" style={{fontWeight: "bold",color: "#7a7474", fontSize: "12px"}} >Betrag in Landeswährung</label>
                <input
                    onChange={(event) => {
                        setEntryPrice(props.index, props.index2, event.target.value*100);
                    }}
                    type="number"
                    className="form-control"
                    value={props.price.amount/100}
                />
            </div>
        </>
    );
}

export default EntryPurchasePrice;