import {PLATFORM} from "aurelia-framework";

export class ColumnsGenerator {
    generate(cartViewConfig) {
        if (!cartViewConfig) {
            return [];
        }

        const result = this._getDisplayedColumns(cartViewConfig);

        result.has = function (id) {
            let found = false;

            this.forEach(column => {
                if (column.id === id) {
                    found = true;
                    return false;
                }
            });

            return found;
        };

        result.indexOf = function (id) {
            let _key = null;

            this.forEach((column, key) => {
                if (column.id === id) {
                    _key = key;
                    return false;
                }
            });

            return _key;
        };

        if (result.indexOf('itemPrice') > 0) {
            result.footer = {
                skipColspanLeft: result.indexOf('itemPrice'),
                skipColspanRight: result.length - (result.indexOf('itemPrice') + 2)
            };
        } else {
            result.footer = {
                skipColspanLeft: result.indexOf('price'),
                skipColspanRight: result.length - (result.indexOf('price') + 2)
            };
        }

        return result;
    }

    _getDisplayedColumns(cartViewConfig) {
        if (!cartViewConfig || !cartViewConfig.columns) {
            return [];
        }

        const columns = this._cloneColumnsWithRegardsToOrder(cartViewConfig.columns);

        columns.forEach(column => this._addViewToColumn(column));

        return columns;
    }

    _cloneColumnsWithRegardsToOrder(columns)
    {
        // clone columns with regards to order in src/order/tourism/cart-view/items-list-header.html

        const requiredOrder = [
            'sortable',
            'selectable',
            'colorCode',
            'title',
            'hotelRating',
            'fromDate',
            'toDate',
            'itemDuration',
            'amount',
            'roomAmount',
            'serviceProvider',
            'purchaseCancellation',
            'purchaseRequestOptionDate',
            'APInvoices',
            'purchasePrice',
            'calculatedPurchaseUnitPrice',
            'calculatedPurchasePrice',
            'profitMargin',
            'profitMarginPercentage',
            'taxPercentage',
            'price',
            'itemPrice',
            'commission',
            'status',
            'edit',
        ];

        const result = [];

        requiredOrder.forEach(
            columnId => {
                const column = columns.find(column => column.id === columnId);
                if (column) {
                    result.push(column);
                }
            }
        );

        return result;
    }

    _addViewToColumn(column) {
        column.view = "./columns/column-" + column.id + ".html";
    }
}

PLATFORM.moduleName('./column-amount.html');
PLATFORM.moduleName('./column-roomAmount.html');
PLATFORM.moduleName('./column-APInvoices.html');
PLATFORM.moduleName('./column-colorCode.html');
PLATFORM.moduleName('./column-commission.html');
PLATFORM.moduleName('./column-edit.html');
PLATFORM.moduleName('./column-fromDate.html');
PLATFORM.moduleName('./column-price.html');
PLATFORM.moduleName('./column-purchaseCancellation.html');
PLATFORM.moduleName('./column-purchaseRequestOptionDate.html');
PLATFORM.moduleName('./column-taxPercentage.html');
PLATFORM.moduleName('./column-itemPrice.html');
PLATFORM.moduleName('./column-purchasePrice.html');
PLATFORM.moduleName('./column-hotelRating.html');
PLATFORM.moduleName('./column-calculatedPurchasePrice.html');
PLATFORM.moduleName('./column-calculatedPurchaseUnitPrice.html');
PLATFORM.moduleName('./column-profitMargin.html');
PLATFORM.moduleName('./column-profitMarginPercentage.html');
PLATFORM.moduleName('./column-selectable.html');
PLATFORM.moduleName('./column-serviceProvider.html');
PLATFORM.moduleName('./column-sortable.html');
PLATFORM.moduleName('./column-status.html');
PLATFORM.moduleName('./column-title.html');
PLATFORM.moduleName('./column-toDate.html');
PLATFORM.moduleName('./column-itemDuration.html');
