import React, {useEffect, useState} from "react";
import {clone} from "lodash";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";

export const Price = (props) => {
    const setPrice = (index, surcharge) => {
        props.setLoading(true);
        let s = clone(props.items);
        s[index].price = surcharge;
        props.setItems(s);
        props.debouncedUpdateTrigger('price', index);
        props.setLoading(false);
    }

    return (
        <>
            <input
                type="number"
                className="form-control"
                value={props.item.price}
                onChange={(event) => {
                    setPrice(props.index, event.target.value);
                }}
            />
        </>
    );
}

export default Price;