import React, {useEffect, useState} from "react";
import {clone} from "lodash";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";
import {SelectControl} from "@wordpress/components";

export const EntryCurrency = (props) => {
    const setEntryCurrency = (index, index2 , currency) => {
        props.setLoading(true);
        let s = clone(props.items);
        s[index].calculatedCostsEntries[index2].price['currency'] = currency;
        s[index].calculatedCostsEntries[index2].securityMargin = '';
        props.setItems(s);
        props.debouncedUpdateTrigger('currency', index, index2);
        //UnsavedChangesPrevent.registerUnsavedChanges(CHANGE_KEY);
        props.setLoading(false);
    }
    const CHANGE_KEY = 'order-calculation';
    return (
        <>
            <div className='form-group col-xs-12 col-md-6'>
                <label htmlFor="exampleInputPassword2" style={{fontWeight: "bold",color: "#7a7474", fontSize: "12px"}} >Währungsauswahl</label>
                <SelectControl
                    value={props.price.currency}
                    options={props.currencies}
                    onChange={(value) => {
                        setEntryCurrency(props.index, props.index2, value);
                    }}
                    id="exampleInputPassword2"  />
            </div>
        </>
    );
}

export default EntryCurrency;