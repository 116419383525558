import React, {useEffect, useState} from "react";
import {Container} from "aurelia-framework";
import {Client} from "../../api/client";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import {sum} from "../../money/money-tools";
import {clone, debounce} from 'lodash';
import {useDebounce} from "../../utilities/debounce";
import {FlashService} from "../../flash/flash-service";
import {Button, Loader, Modal, Popover, Whisper} from "rsuite";
import ValueFormatter from "../../statistics/service/value-formatter";
import update from "immutability-helper";
import {EventAggregator} from "aurelia-event-aggregator";
import {Chart} from "../../statistics/time-aggregation/chart";
import moment from "moment-timezone";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";
import {__} from "@wordpress/i18n";
import {SelectControl} from "@wordpress/components";
import EntryPurchasePrice from "./EntryPurchasePrice";
import EntryCurrency from "./EntryCurrency";
import CurrencyRate from "./CurencyRate";
import CurrencyRateNote from "./CurencyRateNote";
import CalculationType from "./CalculationType";
import Surcharge from "./Surcharge";
import Price from "./Price";
import Amount from "./Amount";

export const ServiceCalculation = (props) => {
    const {order} = props;
    const client = Container.instance.get(Client);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const flashService = Container.instance.get(FlashService);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [currencies, setCurrencies] = useState(0);
    const ea = Container.instance.get(EventAggregator);
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const CHANGE_KEY = 'order-calculation';

    const fetchItems = async (services = null, changedField = null, index1 = null, index2 = null) => {
        setLoading(true);
        try {
            let data = (await client.post(
                "order-service-calculation/query/" + order.id,
                {
                    services: services,
                    changedField: changedField,
                    index1: index1,
                    index2: index2
                }
            )).data;
            setTotal(data.total);
            setItems(data.items);
            setCurrencies(data.currencies);
            setLoading(true);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const debouncedUpdateTrigger = debounce((ChangedField, index1, index2) => {
        fetchItems(items,ChangedField, index1, index2);
    }, 2000);

    const patchOrder = async () => {
        setSaving(true);

        const url = `order-service-calculation/update-margins/${order.id}`;

        const payload = {
            items: items,
        };
        try {
            await client.patch(url, payload);

            ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});

            flashService.success('Erfolgreich gespeichert.');
            UnsavedChangesPrevent.unregisterUnsavedChanges(CHANGE_KEY);
        } catch (e) {
            flashService.error(e.data.message ?? 'Kalkulation konnte nicht geladen werden');
            setLoading(false);
        } finally {
            setSaving(false);
        }
    };
    const LoadingValue  = (value) => {
        return <>
            {loading ? <Loader/> : <Whisper trigger="hover" placement="auto" speaker={<div></div>}>
                <span>{value}</span>
            </Whisper>}
        </>
    }

    useEffect(() => {
        fetchItems();

    }, [order.id]);
    return (
        <>
            <div className="panel order-calculation" >
                <div className="panel-body">
                    <table className="table table-condensed table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Leistungen</th>
                                <th>EK Preis</th>
                                <th>Berechnungsart</th>
                                <th>Erwachsene</th>
                                <th>EK in EUR</th>
                                <th>Marge in %</th>
                                <th>VK in EUR</th>
                                <th>Anzahl</th>
                                <th>VK Gesamt in EUR</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            items.map((item, index) => {
                                return <tr>
                                    <td>
                                        {item.title}
                                        {item.optional &&
                                            <span> (Optional)</span>
                                        }
                                        <br /><br />
                                        { item.participantsName.length > 0 &&
                                        <p>
                                            <b>Teilnehmer</b>:<br />
                                            {item.participantsName.map((particpant) => {
                                                return <div>{particpant}</div>
                                            })}
                                        </p>
                                        }
                                    </td>
                                    <td >
                                        {
                                            item.calculatedCostsEntries.map((entry, index2) => {
                                                return <div style={{background:'#eee',padding: '10px'}}>
                                                {item.calculatedCostsEntries.length > 1 && <label>{entry.creditor+ ' : '}</label> }
                                                    <div class="row">
                                                        <EntryPurchasePrice
                                                            index={index}
                                                            index2={index2}
                                                            price={entry.price}
                                                            items={items}
                                                            setLoading={setLoading}
                                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                                            setItems={setItems}
                                                        />
                                                        <EntryCurrency
                                                            index={index}
                                                            index2={index2}
                                                            price={entry.price}
                                                            items={items}
                                                            setLoading={setLoading}
                                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                                            setItems={setItems}
                                                            currencies={currencies}
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <CurrencyRate
                                                            index={index}
                                                            index2={index2}
                                                            entry={entry}
                                                            items={items}
                                                            setLoading={setLoading}
                                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                                            setItems={setItems}
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <CurrencyRateNote
                                                            index={index}
                                                            index2={index2}
                                                            entry={entry}
                                                            loading={loading}
                                                            items={items}
                                                            setLoading={setLoading}
                                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                                            setItems={setItems}
                                                        />
                                                    </div>

                                                </div>
                                            })
                                        }
                                    </td>
                                    <td>
                                        <CalculationType
                                            index={index}
                                            items={items}
                                            item={item}
                                            setLoading={setLoading}
                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                            setItems={setItems}
                                        />
                                    </td>
                                    <td >{item.personCount}</td>
                                    <td>{LoadingValue(item.purchaseShow + ' €')}</td>
                                    <td>
                                        <Surcharge
                                            index={index}
                                            items={items}
                                            item={item}
                                            setLoading={setLoading}
                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                            setItems={setItems}
                                        />
                                    </td>
                                    <td>
                                        <Price
                                            index={index}
                                            items={items}
                                            item={item}
                                            setLoading={setLoading}
                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                            setItems={setItems}
                                        />
                                    </td>
                                    <td >
                                        <Amount
                                            index={index}
                                            items={items}
                                            item={item}
                                            setLoading={setLoading}
                                            debouncedUpdateTrigger={debouncedUpdateTrigger}
                                            setItems={setItems}
                                        />
                                    </td>
                                    <td>
                                        {LoadingValue( item.totalPrice + ' €')}
                                    </td>
                                </tr>
                            })
                        }
                        <tr>
                            <th>Gesamtpreis</th>
                            <th colSpan="7" ></th>
                            <th>{total} €</th>
                        </tr>
                        </tbody>
                    </table>

                    {LoadingValue(<button onClick={() => {
                        patchOrder()
                    }} className="btn btn-primary pull-right" style={{marginTop: '10px'}} type="button">
                        { saving ? <Loader></Loader> : 'Speichern'}
                    </button>)}
                </div>
            </div>
        </>
    );
};

export default ServiceCalculation;
