import React, {useEffect, useState} from "react";
import {clone} from "lodash";
import {UnsavedChangesPrevent} from "../../form/unsaved-changes-prevent";
import {Loader} from "rsuite";

export const CurrencyRateNote = (props) => {
    const setCurrencyRateNote = (index, index2 , rate) => {
        let s = clone(props.items);
        s[index].calculatedCostsEntries[index2].currencyRateNote = rate;
        props.setItems(s);
    }
    const CHANGE_KEY = 'order-calculation';
    return (
        <>
            {!props.loading && props.entry.price.currency != "EUR" && ((props.entry.currencyRate && props.entry.defaultCurrencyRate && props.entry.currencyRate != props.entry.defaultCurrencyRate)) ?
                <div className="form-group col-xs-12 col-md-12">
                    {props.loading ? <Loader/> :<>
                        <label htmlFor="exampleInputPassword1"
                               style={{
                                   fontWeight: "bold",
                                   color: "#7a7474",
                                   fontSize: "12px"
                               }}>Begründung für den abweichenden Kalkulationskurs*</label>
                        <textarea
                            style={!props.entry.currencyRateNote || props.entry.currencyRateNote == '' ? {border: '1px solid red'} : {}}
                            className="form-control"
                            rows="4"
                            onChange={(event) => {
                                setCurrencyRateNote(props.index, props.index2, event.target.value);
                            }}
                            required>{props.entry.currencyRateNote}</textarea></> }
                </div>
                : ''}
        </>
    );
}

export default CurrencyRateNote;